// eslint-disable-next-line no-redeclare,no-unused-vars
function tableButton(cont, param = {}) {
  var object = param.object;
  var objectSub = param.objectSub;
  var rowPopup;
  var btn = $(
    '<a id="' + object + 'Btn" class="nav-link link-secondary"></a>'
  ).appendTo(cont);
  $(
    '<i class="icon fa-regular fa-' +
      getParamSettings(param, 'icon') +
      ' fa-lg fa-fw"></i>'
  ).appendTo(btn);
  var tip = param.tip;
  if (!tip) tip = getParamSettings(param, 'label');
  btn.addTooltip(tip);

  // click
  if (param.click) btn.on(click, param.click);
  else
    btn.on(click, function () {
      // open in popup
      if (param.popup && rowPopup) {
        ajax({
          object: object,
          objectSub: objectSub,
          id: rowPopup.id,
          callback: function () {
            var row = getData(object, rowPopup.id);
            buildPopup({
              title: row[param.popup.title],
              content: row[param.popup.content],
            });
            rowPopup = null;
          },
        });
      }

      // open table
      else {
        var pm = {object: object, objectSub: objectSub};
        pm.type = getParamSettings(param, 'type');
        if (!pm.type) pm.type = 'table';
        ajax(pm);
      }
    });

  // badge number
  if (param.badge) {
    btn.iconNumber = $(
      '<span class="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 translate-middle"></span>'
    ).appendTo(btn);
    btn.ids = [];
    btn.numberSets = [];
    var pm = {};
    if (param.badge.where) pm.where = param.badge.where;
    else if (param.badge.objectSub) pm.objectSub = param.badge.objectSub;
    registerTable(btn, object, pm);
    btn.update = function (row = {}) {
      if (checkWhere(row, btn.filterJs, {object: object, noConvert: true}))
        btn.numberSets.push(row.id);
      else if (!inArray(row.id, btn.ids))
        btn.numberSets = arrayRemove(btn.numberSets, row.id);
      btn.iconNumber.text(btn.numberSets.length);
      if (btn.numberSets.length) btn.iconNumber.removeClass('d-none');
      else btn.iconNumber.addClass('d-none');
    };
    btn.add = function (row, param) {
      btn.ids.push(row.id);
      btn.update(row);
      // register(btn, object, row.id, numberField);
      if (
        inArray(object, ['notification', 'mailStat', 'followup', 'bug']) &&
        param &&
        param.event
      )
        sendNotification({
          title: 'Benachrichtigung',
          message: row.short,
        });
    };
    btn.rem = function (row) {
      btn.ids = arrayRemove(btn.ids, row.id);
      btn.update(row);
    };
    btn.val = function (value, object, id) {
      var row = getData(object, id);
      if (row) btn.update(row);
    };
    var pm2 = {
      object: object,
      objectSub: pm.objectSub,
      send: {where: pm.where},
    };
    if (param.popup)
      pm2.callback = function (xhr) {
        if (xhr.ids && xhr.ids[0]) {
          var row = getData(object, xhr.ids[0]);
          if (row.unseen) {
            rowPopup = row;
            if (param.open && checkWhere(rowPopup, param.open))
              btn.trigger(click);
          }
        }
      };
    ajax(pm2);
  }

  return btn;
}
